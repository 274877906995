import React, { useState } from "react";
import "./login.scss";
import logo from "../../img/logo_next_day.png";
import { Box, FormControl, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import { Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import Notiflix, { Notify } from "notiflix";
import { createData } from "./apollo";
import { useMutation } from "@apollo/client";
import { USER_KEY, messageError, messageSuccess, placeholderImage } from "../../helper";
import { url_path_images, url_upload_files, url_upload_images } from "../../helper/url";
import compressImage from "../../helper/uploadFile";
import axios from "axios";
export default function FormRegister() {
  const [addData] = useMutation(createData);
  const [image, setImage] = useState("");
  const [getFile, setFile] = useState("");
  // Choose image and upload image
  const handleChangeImageUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.onload = async (e) => {
        setImage(e.target?.value);
        const formData = new FormData();
        formData.append("file", compressedFile);
        const res = await axios.post(url_upload_images, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setImage(filename);
        } else {
          setImage("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };
  // upload file
  const handleChangeFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setFile(e.target?.value);
        const formData = new FormData();
        formData.append("files", file);
        const res = await axios.post(url_upload_files, formData, {
          headers: {
            Authorization: localStorage.getItem(USER_KEY),
          },
        });
        const filename = res?.data?.filename;
        if (res.status === 200 && filename) {
          setFile(filename);
        } else {
          setFile("");
          Notify.messageError("ມີຂໍ້ຜິດພາດ");
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
      messageError("ອັບໂຫຼດບໍ່ສຳເລັດ");
    }
  };
  const { handleChange, errors, values, handleSubmit, isSubmitting, setFieldValue, resetForm } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      profileImage: "",
      files: "",
      position: "",
      dateOfBirth: "",
    },
    enableReinitialize: false,
    validate: (values) => {
      const errors = {};
      if (!values.firstName) errors.firstName = "ກະລຸນາປ້ອນຊື່";
      if (!values.lastName) errors.lastName = "ກະລຸນາປ້ອນນາມສະກຸນ";
      if (!values.phoneNumber) errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
      if (!getFile) errors.files = "ກະລຸນາເລືອກເອກະສານ";
      if (!values.position) errors.position = "ກະລຸນາເລືອກຕຳແໜ່ງ";
      if (!values.dateOfBirth) errors.dateOfBirth = "ກະລຸນາເລືອກວັນເດືອນປີເກີດ";
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await addData({
          variables: {
            data: {
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: Number(values.phoneNumber),
              profileImage: String(image),
              files: String(getFile),
              dateOfBirth: values.dateOfBirth,
              description: values.description,
              position: values.position,
            },
          },
        });
        Notiflix.Loading.remove();
        messageSuccess("ບັນທຶກສຳເລັດ");
        resetForm((values = ""));
        setImage("");
        setFile("");
      } catch (error) {
        console.log(error);
        Notiflix.Loading.remove();
        messageError("ການເພີ່ມຂໍ້ມູນຜິດພາດ");
      }
    },
  });
  return (
    <>
      <div className="card mt-1">
        <div class="card border m-auto" style={{ width: "95%" }}>
          <div class="card-header bg-danger">
            <img src={logo} style={{ width: "50%", height: "40%" }} />
            <h5 className="text-white text-center">ຟອມສະໝັກເຂົ້າເຮັດວຽກໃນບໍລິສັດ ອານຸສິດ ຂົນສົ່ງດ່ວນ</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <Col md={12}>
                <div className="form-group">
                  <div style={{ textAlign: "center" }} className="long">
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleChangeImageUpload}
                      accept="image/png,image/jpeg"
                      hidden
                    />
                    <label for="file-upload">
                      <div>
                        {image ? (
                          <img
                            src={image ? url_path_images + image : placeholderImage(200, 200)}
                            alt={image}
                            style={{
                              height: 150,
                              width: 200,
                              borderRadius: 7,
                            }}
                          />
                        ) : (
                          <>
                            <i
                              className="fa-solid fa-user-plus p-2"
                              style={{ fontSize: "100px", borderRadius: "10%", backgroundColor: "#ededed" }}
                            />
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <TextField
                  margin="dense"
                  name="firstName"
                  label="ຊື່:"
                  error={errors.firstName}
                  onChange={handleChange}
                  type="text"
                  value={values.firstName}
                  className="w-100 "
                />
              </Col>
              <Col md={6}>
                <TextField
                  margin="dense"
                  label="ນາມສະກຸມ:"
                  name="lastName"
                  error={errors.lastName}
                  value={values.lastName}
                  onChange={handleChange}
                  type="text"
                  className="w-100 "
                />
              </Col>
              <Col md={12}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" error={errors.dateOfBirth} htmlFor="uncontrolled-native">
                      ວັນເດືອນປີເກີດ:
                    </InputLabel>
                  </FormControl>
                </Box>
                <TextField
                  margin="dense"
                  name="dateOfBirth"
                  error={errors.dateOfBirth}
                  value={values.dateOfBirth}
                  onChange={handleChange}
                  type="date"
                  className="w-100  mt-4"
                />
              </Col>
              <Col md={12}>
                <TextField
                  margin="dense"
                  name="phoneNumber"
                  error={errors.phoneNumber}
                  value={values.phoneNumber}
                  label="ເບີໂທ  85620:"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      return handleChange(e);
                    }
                    if (parseInt(value) > 0 && parseInt(value) <= 99999999 && String(value)?.length <= 8) {
                      handleChange(e);
                    }
                  }}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  className="w-100 "
                />
              </Col>
              <Col md={12}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" error={errors.position} htmlFor="uncontrolled-native">
                      ເລືອກຕຳແໜ່ງ
                    </InputLabel>
                    <NativeSelect
                      inputProps={{
                        name: "position",
                        id: "uncontrolled-native",
                      }}
                      name="position"
                      value={values.position}
                      error={errors.position}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="CUSTOMER_SERVICE">ພ/ງ ບໍລິການລູກຄ້າ</option>
                      <option value="HR">ບຸກຄາລະກອນ</option>
                      <option value="ACCOUNTANT">ບັນຊີ</option>
                      <option value="FINANCE">ການເງິນ</option>
                      <option value="LAW">ກົດໝາຍ</option>
                      <option value="STATE_DIRECTOR">ຫົວໜ້າບໍລິຫານສາຂາ</option>
                      <option value="BRANCH_DIRECTOR">ຫົວໜ້າສາຂາ</option>
                      <option value="IT">ໄອທີ</option>
                      <option value="CALL_CENTER">ຕ້ອນຮັບ</option>
                      <option value="ADMIN">ເເອັດມິນ</option>
                      <option value="DRIVER_DIRECTOR">ຫົວໜ້າໂຊເເຟີ</option>
                      <option value="DRIVER">ຄົນຂັບລົດ</option>
                      <option value="AUDITION_DIRECTOR">ຫົວໜ້າສູນຄັດແຍກ</option>
                      <option value="AUDITION_STAFF">ພ/ງ ສູນຄັດແຍກ</option>
                      <option value="SHIPPER">ໄລເດີ້</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
              </Col>
              <Col md={12}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" error={errors.dateOfBirth} htmlFor="uncontrolled-native">
                      ອັບໂຫຼດເອກະສານ CV:
                    </InputLabel>
                  </FormControl>
                </Box>
                <TextField
                  margin="dense"
                  onChange={handleChangeFile}
                  type="file"
                  name="file"
                  error={errors.files}
                  accept="file/pdf,image/png,image/jpeg"
                  className="w-100  mt-4"
                />
                <label>
                 ຊື່ຟາຍ: {getFile}
                </label>
              </Col>
              <Col md={12}>
                <Form.Group className=" mt-1" controlId="exampleForm.ControlInput1">
                  <Form.Label>ອືນໆ:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="..."
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    className="form-control"
                  />
                </Form.Group>
              </Col>
            </div>
            <button className="btn btn-success w-100 float-end mt-2" disabled={isSubmitting} onClick={() => handleSubmit()}>
              <i className="fa-solid fa-paper-plane me-1" />
              ຢືນຢັນສົ່ງຟອມ
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
