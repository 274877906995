import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Paths } from "./app";
import FormRegister from "../Screens/login/Form";

const publicRoute = createBrowserRouter([
  { path: "/", element: <Navigate to={Paths.login} /> },
  { path: "/index", element: <FormRegister /> },
  { path: "*", element: <Navigate to={Paths.login} /> },
]);

export default function RoutesApp() {
  return <RouterProvider router={publicRoute} />;
}
