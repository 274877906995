import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, from, createHttpLink } from "@apollo/client";
import "./index.scss";
import Routes from "./Routes";
import { setContext } from "@apollo/client/link/context";
import { clearLocalStorage, USER_KEY } from "./helper";
import { onError } from "@apollo/client/link/error";
import Notiflix from "notiflix";
const api = "https://dash.api.anousith-express.com/graphql";
// const api = "http://localhost:4500/graphql";
export const AppContext = React.createContext();
export default function App() {
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(USER_KEY);
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
  const client = new ApolloClient({
    link: from([
      authLink.concat(
        createHttpLink({
          uri: api,
        })
      ),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  return (
    <>
      <div className="bg-white vh-100">
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      </div>
    </>
  );
}
